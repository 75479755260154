.game { 
    text-align: center; 
}
.board { 
    display: grid; 
    grid-template-columns: repeat(3, 100px); 
    gap: 5px; 
    justify-content: center; 
}
.cell { 
    width: 100px; 
    height: 100px; 
    font-size: 2em; 
    cursor: pointer; 
}
.reset { 
    margin-top: 20px; 
    padding: 10px 20px; 
    font-size: 1em; 
    cursor: pointer; 
}
.status { 
    margin-bottom: 10px; 
    font-size: 1.5em; 
}