$primaryColor: #EBA602;
$primaryDark : #C98E00;
$primaryLight: #FFF2D4;


$maxMobileBreakpoint: 767px;

$minTabletBreakpoint: 768px;
$maxTabletBreakpoint: 1440px;

$minDesktopBreakpoint: 1441px;
$maxDesktopBreakpoint: 1919px;

$minLargeDesktopBreakpoint: 1920px;


