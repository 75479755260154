@use '../../styles/vars' as v;


.stack {
    &__logo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 20px;
    }
}

