@use '../../styles/vars' as v;


.experience {
    padding: 1rem 0;

    &__list {
        padding: 1rem;

        @media screen and (min-width: v.$minTabletBreakpoint) {
            max-width: 900px;
            margin: 0 auto;
        }
        &__item {
            display: flex;
            justify-content: space-between;
            font-size: 1.1em;
            margin-bottom: 2rem;

            &__button {
                background-color: transparent;
                padding: 0.3rem 0.5rem;
                border: 2px solid #EBA602;
                border-radius: 5px;
                font-size: .8rem;
                font-weight: bold;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    background-color: #EBA602;
                    color: #fff;
                }
            }
        }
    }
}