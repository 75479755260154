@use '../../styles/vars' as v;

.formation {

    &__title {
        font-weight: 700;
        color: v.$primaryColor;
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }
}