.section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem; 
  
    h2 {
      white-space: nowrap;
      font-size: 1.2rem;
      font-weight: bold;
    }
  
    .line {
      flex-grow: 1;
      height: 2px;
      background-color: #EBA602;
    }
  }
  