@use 'reset';
@use 'vars';

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} 

h1 {
  font-family: 'Lucida grande', sans-serif;
}

h2, h3, h4, h5, h6 {
  font-family: 'Copperplate', sans-serif;
}

p, div, a, span, li, input, button {
  font-family: 'Montserrat', sans-serif;
}