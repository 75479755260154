@use '../../styles/vars' as v;

.app {

    @media screen and (min-width: v.$minTabletBreakpoint) {
        padding: 1.6em;
    }
}



