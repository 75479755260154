@use '../../styles/vars' as v;

 .intro {

    &__subtitle {
        color: v.$primaryColor;
    }

    &__about {
        background-color: v.$primaryLight;

        &__contact, &__infos {
            @media screen and (min-width: v.$minTabletBreakpoint) {
                width: 50%;
            }
        }
    }

    &__image {
        display: none;

        // tablet and larger
        @media screen and (min-width: v.$minTabletBreakpoint) {
            display: block;
            margin-top: -100px;
        }
    }

    
}