@use '../../styles/vars' as v;

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    text-align: center;
    position: relative;
  }
  
  .modal-content button {
    margin-top: 10px;
    padding: 8px 12px;
    border: none;
    background: v.$primaryColor;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .modal-content button:hover {
    background: white;
    color: v.$primaryColor;
    border: 2px solid v.$primaryColor;
  }
  