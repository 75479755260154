@use '../../styles/vars' as v;


.links {
    &__link {
        display: block;
        color: v.$primaryColor;
        font-size: 1.2rem;
        text-decoration: none;
        transition: color 0.3s;
        margin: 2rem 0;

        &:hover {
            color: v.$primaryDark;
        }
    }
}

